.background {
  fill: none;
  pointer-events: all;
}

.feature {
  fill: #aaa;
}

.feature.active {
  fill: #085394;
}

#state-borders {
  fill: none;
  stroke: #fff;
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  pointer-events: none;
}

#region_map {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 110px;
}
