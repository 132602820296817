html {
    --color-blue1: #085394;
    --color-blue2: #39BCDD;
    --color-green: #70B42B;
    --color-yellow: #F8C22D;
    --color-purple: #60327A;
    --color-red: #961610;
    --color-gray1: #33383A;
    --color-gray2: #626364;
    --color-gray3: #959B9E;
    --color-subtle-gray: #F2F4F5;
    --color-subtle-blue: #E8F6FA;
    --color-black: #000000;
    --color-white: #FFFFFF;
    --font-main: 'Montserrat', sans-serif;
    --font-alt: 'adelle', serif;
}

body { margin-top: 0; }

.section-riskFactors {
    font-family: var(--font-main);
}

.section-riskFactors a {
    color: var(--color-blue1); 
    text-underline-offset: 4px;
    font-weight: 500;
}

.section-riskFactors p {
    line-height: 1.75;
    font-size: 16px;
    color: var(--color-gray1);
}

.section-riskFactors .protectiveFactors-items strong,
.section-riskFactors .riskFactor > strong
{
    text-transform: uppercase;
    color: var(--color-gray2);
    padding-bottom: 5px;
}

.section-riskFactors .protectiveFactors-items strong:not(:first-child) 
{
    padding-top: 20px;
}

.section-riskFactors .protectiveFactors-icon
{
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 8px;
}

.section-riskFactors .section-subheader {
    line-height: 1.5;
    font-size: 20px;
}

.section-riskFactors .riskFactor:not(:first-child) { margin-top: 30px; }

.section-toolkit { background-color: transparent; }

.section-joinUs { display: none; }

.-bullet ul {

    padding-left: 20px;
    list-style-type: none !important;
}

.-bullet ul li
{
    position: relative;
    font-size: 16px;
    list-style-type: none !important;
}

.-bullet ul li::before {
    top: 0.25em;
    font-family: "Font Awesome 6 Free";
    font-size: 0.8em;
    font-weight: 900;
    content: "\f04b";
    color: var(--color-green);
    position: absolute;
    left: -18px;
}

.-bullet ul li:not(:last-child) {
    margin-bottom: 10px;
}
