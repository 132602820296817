body {
  min-height: 100%;
}

h2 {
  line-height: 32px;
}

.brand-purple-link {
  text-decoration: none;
  color: #60327A;
}

.brand-yellow {
  color: #F8C22D;
}

.brand-purple {
  color: #60327A;
}

.brand-blue {
  color: #085394;
}

section {
  opacity: 1.0;
}

.section-hero {
  background-image: url("images/hero.jpg");
  text-align: center;
  margin-bottom: 70px;
  padding-top: 10%;
  height: 100%;

  p {
    text-align: justify;
  }
}

.section-intro {
  padding-bottom: 0;

  p {
    max-width: 998px;
  }
}

.section-meetAnna {
  padding-bottom: 5%;
}

.section-meet-anna {
  background-image: url("images/blue-girl.jpg");
  background-position: center right;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
  padding-top: 115px;
  margin-bottom: 115px;
  text-align: center;

  p.notes {
    margin-bottom: 50px;

    a {
      color: #fff;
    }
  }

  p.more {
    a {
      border: 1px solid #fff;
      color: #fff;
      margin-bottom: 30px;
      padding: 15px 30px;
      text-align: center;
      text-decoration: none
    }

    @media (min-width:660px) {
      a {
        margin-bottom: 0;
        margin-right: 30px
      }

      a:hover {
        border-color: #ccc;
        color: #ccc
      }

      a:last-child {
        margin-right: 0
      }
    }
  }
}

.section-calculator-banner {
  background-image: url("images/blue-boy.jpg");
  background-position: center right;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
  padding-top: 190px;
  text-align: center;

  p.notes {
    margin-bottom: 50px;

    a {
      color: #fff;
    }
  }

  p.more {
    a {
      border: 1px solid #fff;
      color: #fff;
      margin-bottom: 30px;
      padding: 15px 30px;
      text-align: center;
      text-decoration: none
    }

    @media (min-width:660px) {
      a {
        margin-bottom: 0;
        margin-right: 30px
      }

      a:hover {
        border-color: #ccc;
        color: #ccc
      }

      a:last-child {
        margin-right: 0
      }
    }
  }
}

.section-riskFactors .section-subheader {
  font-size: 22px;
}

.riskFactor p {
  font-size: 20px;
}

.section-impacts {
  padding-bottom: 20px;
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;

  .choose-county {
    margin-bottom: 35px;

    a {
      color: #085394;
    }

    .section-header {
      color: #085394;
    }
  }

  .questions {
    margin-bottom: 35px;

    a {
      color: #35632a;
    }

    .section-header {
      color: #35632a;
    }
  }

  .calculation-info {
    margin-bottom: 35px;

    a {
      color: #60327A;
    }

    .section-header {
      color: #60327A;
    }
  }

  a {
    text-decoration: none;
  }

  p.form {
    text-align: center;
  }

  .impacts-row {
    display: flex;
  }

  .impacts-column-6 {
    flex: 1 1 auto;
    margin: 0px 20px;
  }

  @media only screen and (max-width: 1260px) {
    .impacts-row {
      display: block;
    }

    .impacts-column-6 {
      width: 100%;
    }
  }
}

.section-risk-factors {
  background-image: url("images/boy-map.jpg");
  background-position: center right;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
  padding-top: 115px;
  margin-bottom: 115px;
  text-align: center;

  p.notes {
    margin-bottom: 50px;

    a {
      color: #fff;
    }
  }

  p.more {
    a {
      border: 1px solid #fff;
      color: #fff;
      margin-bottom: 30px;
      padding: 15px 30px;
      text-align: center;
      text-decoration: none
    }

    @media (min-width:660px) {
      a {
        margin-bottom: 0;
        margin-right: 30px
      }

      a:hover {
        border-color: #ccc;
        color: #ccc
      }

      a:last-child {
        margin-right: 0
      }
    }
  }
}

.section-childAbuse {
  padding-bottom: 80px;

  p.more {
    a {
      border: 1px solid #085394 !important;
      color: #085394 !important;
      margin-bottom: 30px;
      padding: 15px 30px;
      text-align: center;
      text-decoration: none
    }
  }

  .calculation-btn {
    margin-top: 5rem;
    text-align: center;
  }

  .impacts-title {
    text-align: center;
    max-width: 1200px;
    margin: 60px auto 0 auto;
    color: #085394;
    font-size: 38px;
    line-height: 1.2;
  }
}

.form-btn {
  border: 1px solid #fff;
  color: #fff;
  background-color: #085394;
  ;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 10px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-checkbox-row {
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.form-field {
  padding: 12px 20px;
  width: 50%;
}

.recaptcha {
  padding: 12px 20px;
  width: 100%;
}

.justify-content-center {
  justify-content: center !important;
}

.section-toolkit {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #F4FAFC;

  .section-content {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #39bcdd;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #39bcdd;
  }
}

p.section-introduction {
  text-align: center;
  font-size: 125%;
}


.section-joinUs {
  .joinUs-triangle {
    color: #323b41;
  }

  .share-button {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    border-left: none;
    border-right: none;
    background-color: #fff;
    padding: 10px 10px;
    text-align: left;
    text-decoration: none;
    width: 100%;

    >* {
      vertical-align: middle;
    }

    h3 {
      color: #085394;
      display: inline-block;
      padding-left: 10px;
    }

    img {
      width: 50px;
    }

    .triangle {
      color: #085394;
      float: right;
      padding: 20px 10px;
    }

    .joinUs-triangle {
      color: #085394;
    }
  }

  #social-share-link {
    text-align: center;
  }
}

.childAbuseGraphic {
  margin-top: -40px;
}

.select2-search__field {
  font-size: 15px !important;
}

p.section-introduction {
  text-align: center;
  font-size: 125%;
}

a.nav-logoAndTitle {
  color: #085394;
  text-decoration: none;
}

nav {
  width: 100% !important;
  max-width: 100% !important;
  overflow: hidden;
}

.menu-icon {
  display: none;
}

.navbar-small {
  display: none;
}

.nav-links a.menu {
  display: none;
}

@media screen and (max-width: 840px) {

  // hide links
  .nav-links a:not(:last-child) {
    display: none;
  }

  .top-nav {
    box-shadow: none;
  }

  .nav-title {
    line-height: 0.7rem;
  }

  .nav-links a.menu {
    display: block;

    .menu-icon {
      padding: 25px 0;
      display: block;
      height: 100%;
      width: 60px;
      position: absolute;
      top: 0;
      right: 15px;
    }
  }

  .navbar-small {
    display: block;
    position: fixed;
    background-color: #fff;
    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .03);
    width: 100%;
    padding: 12px;
    line-height: 1.8rem;
    text-align: center;
    z-index: 999999;
  }
}

.section-subheader {
  color: #737373;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px
}

@media (min-width:660px) {
  .section-subheader {
    font-size: 17px;
    line-height: 23px;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px
  }
}

.public-page {
  .section-header {
    padding: 50px 0px;
    margin-bottom: 0px;
  }
}

.table {
  width: 100%;

  th,
  td {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  th {
    text-align: inherit;
  }
}

.faq {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  break-inside: avoid;
  width: 100%;
  margin-bottom: 19px
}

.faq strong {
  display: block;
  color: #085394;
}

.faq p,
.faq li,
.faq th,
.faq td {
  color: #085394
}

.address-holder {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.address-holder::after {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.address-1 {
  width: 50%;
  float: left;
}

.clear-float {
  clear: both;
}

.region-map {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}

.footer-icon {
  padding: 10px;
  border: solid 1px #fff;
}

.footer-icon:hover {
  background-color: rgba(255, 255, 255, 0.178);
}

.collab-title {
  color: #5c6064;
  letter-spacing: 0.06em;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
}

.glider-contain {
  padding: 0 30px !important;
}

.glider-items {
  border: none !important;
  margin: 20px;
  filter: grayscale(100%);
}

.glider-items:hover {
  filter: none;
}

.glider-next,
.glider-prev {
  width: auto !important;
  top: 45% !important;
  background: none !important;
}

.glider-prev {
  left: -20px !important;
}

#overide-height-and-width {
  width: 400px !important;
  height: 300px !important;
}

.protectiveFactors-items {
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
}

.protectiveFactors-icon {
  float: left;
  margin-right: 10px;
  background-color: #39bcdd;
  // border-radius:  50%;
}

.risk-factor-button {
  color: #60327A;
}

.meetAnna-careCategories {
  font-size: 16px;
}

@media (min-width: 1640px) {
  .region-map {
    margin-top: -45px;
    width: 450px;
  }
}

@media (min-width: 1040px) {
  .riskFactors-all {
    column-count: 2;
  }

  .region-map {
    height: 500px;
    width: 450px;
    margin: 0 auto;
  }

  .section-risk-factors {
    padding-bottom: 160px;
  }
}

@media (min-width: 660px) {
  .riskFactors-all {
    grid-column-gap: 60px;
    column-gap: 60px;
  }

  .meetAnna-infoBox p {
    font-size: 22px;
  }
}

@media (max-width: 660px) {
  .section-hero.playbook {
    padding-top: 30px !important;
    height: inherit;
  }

  .region-map {
    margin-top: 0;
    height: 100% !important;
    width: 100%;
  }
}

@media (max-width: 1040px) {
  .section-hero.playbook {
    height: inherit;
  }

  .region-map {
    margin-top: 0;
    height: 500px;
  }
}

.highcharts-tooltip {

  p,
  ol,
  li {
    font-size: 17px;
  }

  li {
    list-style-type: circle;
    padding: 3px;
  }
}


@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  // IE10+ CSS styles go here
  .meetAnna-infoBox {
    width: 100%;
  }


  @media (min-width: 1040px) {
    .section-hero.playbook {
      padding-top: 40px !important;
    }
  }

  @media (max-width: 660px) {
    .section-hero.playbook {
      padding-top: 40px;
    }
  }
}

.childAbuseGraphic_stat p {
  font-size: 18px;
}

.crime-statistic-container {
  color: #085394;
  display: flex;
  margin: 0% 5% 0% 5%
}

.half-width {
  width: 50%;
}

.half-width+h5 {
  color: grey;
  padding-top: 10%;
}

.half-width>h4 {
  margin-bottom: 1%;
}

.risk-list>li {
  margin: 0% 5% 0% 5%;
  display: list-item;
  list-style-type: circle;
  text-align: start;
}

// Playbook CSS
.playbook-page {
  background-color: #E5E5E5;
  padding-bottom: 5%;
}

.playbook {
  background-image: url("images/playbook-cover.png");
  text-align: center;
  margin-bottom: 70px;
  padding-top: 10%;
  height: 100%;
  color: white;

  p {
    text-align: justify;
    font-size: 20px;
  }
}

.playbook-btn {
  background-color: #ED6E0C;
  color: white;
  margin-top: 3%;
  font-weight: 700;
  border: none;
  padding: 1%;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 0px #00000040;
  width: 30%;
}

.summary-btn {
  width: 20%;
  font-size: 80%;

}

.section-playbook {
  width: 90%;
  height: 30%;
  margin: auto;
  margin-top: 5%;
  border: 1px solid #E5E5E5;
  padding: 1%;
  background-color: white;
  color: #085394;
}

.section-playbook+.last {
  margin-bottom: 5%;
}

.expand>.line>h1,
.container>.line>h1 {
  color: #ED6E0C;
  padding-left: 3%;
  font-size: 32px;
}

.expand>.line>.plusminus {
  color: #ED6E0C;
}

.playbook-quote {
  border-left: 1px solid #149FC2;
  margin-left: 3%;
  margin-bottom: 3%;
}

.playbook-quote>p {
  padding-left: 3%;
  margin-bottom: 0;
}

.pads {
  padding: 3%;
}

.expand>.container {
  padding: 3%;
}

.hidden {
  display: none;
}

.playbook-footer {
  margin-top: 10%;
  text-align: center;
  color: #085394;
}

.playbook-footer>h1 {
  padding: 3%;
  margin: auto;
}

.playbook-footer>button {
  margin-bottom: 10%;
}

.playbook-image {
  justify-content: center;
  width: 75%;
}

#pb-how-ex>img.playbook-image {
  width: 100%;
}

.quote {
  font-style: italic;
}

.plusminus {
  float: right;
  font-size: 44px;
  font-weight: 700;
  padding-right: 1%;
}

.line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.line>h1 {
  padding-left: 3%;
  font-size: 32px;
  display: inline
}

.no-link {
  color: white;
  text-decoration: none;
}

.column-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}


// Reporting Paradigm
.reporting-background {
  background-image: url("images/reporting-paradigm-background.png");
}

.reporting-list {
  margin-top: 0%;
  margin-left: 3%;
}

.reporting-bullets {
  list-style-type: disc;
  margin-bottom: 3%;
}

.reporting-numerals {
  list-style-type: upper-roman;
  font-size: 24px;
  margin-bottom: 2%;
}

.reporting-image {
  margin-left: 2%;
  max-width: 600px;
  height: auto;
}

.reporting-data {
  margin-left: 2%;
}

.reporting-number-emphasis {
  font-size: 30px;
  font-weight: 600;
}

.reporting-paradigm-footer {
  text-align: center;
  color: #085394;
}

.reporting-paradigm-footer>h1 {
  padding: 3%;
  margin: auto;
}

.reporting-paradigm-footer>button {
  margin-bottom: 10%;
}